.overlayBody {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    height: auto;
}

.overlaytext {
    color: white;
    font-size: 3rem;
    text-align: center;
    padding: 200px;
    z-index: 50;
}

/* Adjust text size for smaller screens */
@media (max-width: 768px) {
    .overlaytext {
        font-size: 1rem;
        padding: 20px;
        z-index: 50;
    }
}

/* Adjust text size for smaller screens */
@media (max-width: 1024px) {
    .overlaytext {
        font-size: 1.5rem;
        padding: 20px;
        position: fixed;
        top: 75px;
        z-index: 50;
    }
}