bodyGallery {
  background-color: black;
  margin: 0;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  top: 70px;
  position: relative;
}

.gallery-title {
  color: rgb(250, 179, 11);
  font-family: Montserrat, sans-serif;
  font-size: 4rem;
  text-align: center;
  padding: 0 20px;
}

.image-gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  overflow-y: scroll;
  height: calc(100vh - 100px);
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 20px;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  max-width: 100%;
}

@media (max-width: 768px) {
  .gallery-title {
    font-size: 2rem;
    /* Adjust the font size for small devices */
    padding: 0 10px;
  }

  .image-gallery {
    grid-template-columns: 1fr;
  }
}