/* NotFoundPage.css */
.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
    /* Optional: Adjust brightness for overlay effect */
}

.overlay {
    text-align: center;
    color: #fff;
    z-index: 1000;
    /* Optional: Adjust text color */
}

h1 {
    font-size: 3em;
}

p {
    font-size: 1.5em;
}