/* attendance.css */
.sideList {
    margin-left: 0px;
    z-index: 100;
}

.full-class {
    background-color: rgb(185, 13, 13);
    color: white;
    /* Rounded corners */
    padding: 5px 5px;
    margin: 5px 5px;
    border-radius: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    font-size: medium;
    width: 250px;
    height: 60px;
    min-height: 50px;
    box-shadow: #fff;
}

.center {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    overflow-y: auto;
    z-index: 50;
    margin-top: 50px;
    position: relative;
}

.messageContainer {
    width: 50%;
    max-width: 400px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 10;
    position: relative;
}

.warningText {
    color: rgb(250, 179, 11);
    font-size: 2.0em;
    text-align: center;
    font-family: 'Montserrat', 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
}


.diheaderText {
    color: white;
    font-size: 2.0em;
    font-family: 'Montserrat', 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
}

.disubheaderText {
    color: white;
    font-size: 1.2em;
    font-family: 'Montserrat', 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
}

.diregularText {
    color: white;
    color: 0.8em;
    margin-top: 20px;
    font-size: medium;
    font-family: 'Montserrat', 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
}

.dilinkText {
    color: rgb(244, 178, 23);
    color: 0.8em;
    margin-top: 20px;
    font-weight: 500;
    font-size: medium;
    font-family: 'Montserrat', 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
}

.background-image {
    background-image: url('../../../../assets/images/photos/walcrab.jpg');
    /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    position: absolute;
    left: 0px;
    /* Text color on the background image */
}

.gradient-overlay {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0),
            /* Transparent at the top */
            rgba(0, 0, 0, 0.9)
            /* Dark background at the bottom */
        ),
        linear-gradient(to top,
            rgba(0, 0, 0, 0),
            /* Transparent at the bottom */
            rgba(0, 0, 0, 0.9)
            /* Dark background at the top */
        );
}

.vertical-button-list {
    display: flex;
    flex-direction: column;
    /* Display buttons vertically */
    align-items: center;
    /* Center-align buttons horizontally */
    z-index: 90;
    height: 100px;
}

.orange-button {
    background-color: rgb(200, 85, 19);
    color: white;

    /* Rounded corners */
    padding: 10px 10px;
    margin: 5px;
    border-radius: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    font-size: small;
    width: auto;
    min-width: 250px;
    height: 45px;
    box-shadow: #fff;
}


.blue-button {
    background-color: rgb(250, 179, 11);
    color: white;

    /* Rounded corners */
    padding: 5px 5px;
    margin: 5px;
    border-radius: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    font-size: small;
    width: auto;
    min-width: 250px;
    height: 45px;
    box-shadow: #fff;
}

.blue-button:hover {
    border: 2px solid white;
    /* Show the border on hover */
    border-radius: 20px;
}


.green-button {
    background-color: rgb(20, 122, 18);
    color: white;
    /* Rounded corners */
    padding: 10px 10px;
    margin: 5px;
    border-radius: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    font-size: medium;
    width: auto;
    height: 50px;
    box-shadow: #fff;
}

.blue-button:hover {
    border: 2px solid white;
    /* Show the border on hover */
    border-radius: 20px;
}

.second-button:disabled {
    background-color: gray;
}



.second-button {
    background-color: rgb(13, 99, 185);
    color: white;
    /* Rounded corners */
    padding: 5px 5px;
    margin: 5px 5px;
    border-radius: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    font-size: small;
    width: 250px;
    height: 60px;
    min-height: 50px;
    box-shadow: #fff;
}

.second-button:hover {
    border: 2px solid white;
    /* Show the border on hover */
    border-radius: 20px;
}

.second-button:active,
.second-button:focus {
    border: 2px solid white;
    /* Keep the border when button is clicked or focused */
}


.second-button:disabled {
    background-color: gray;
}

.qrcode-button {
    background-color: rgb(7, 169, 56);
    color: white;

    /* Rounded corners */
    padding: 5px 5px;
    margin: 5px;
    margin-left: 10px;
    padding-left: 10px;
    border-radius: 20px;
    cursor: pointer;
    font-size: small;
    width: 250px;
    height: 50px;
    box-shadow: #fff;
}

.third-button {
    background-color: rgb(185, 82, 13);
    color: white;
    /* Rounded corners */
    padding: 5px 5px;
    margin: 5px;
    margin-left: 10px;
    padding-left: 10px;
    border-radius: 20px;
    cursor: pointer;
    font-size: small;
    width: 250px;
    min-height: 40px;
    max-height: 40px;
    box-shadow: #fff;
}

.third-button-content {
    display: flex;
    color: white;
    align-items: center;
    justify-content: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.third-button:hover {
    border: 2px solid white;
    /* Show the border on hover */
    border-radius: 20px;
}

.third-button:active,
.third-button:focus {
    border: 2px solid white;
    /* Keep the border when button is clicked or focused */
}

.yellow-button {
    background-color: rgb(231, 166, 14);
    color: white;

    /* Rounded corners */
    padding: 10px 10px;
    margin: 5px;
    margin-bottom: 10px;
    border-radius: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    font-size: medium;
    width: 250px;
    height: 50px;
    box-shadow: #fff;
}

.yellow-button:hover {
    border: 2px solid white;
    /* Show the border on hover */
    border-radius: 20px;
}

.listsContainer {
    z-index: 50;
    display: flex;

}

@media (max-width: 736px) {

    .regularText {
        display: none;
    }

    .second-button {
        font-size: small;
        width: 250px;
        height: 60px;
        min-height: 50px;
        box-shadow: #fff;
    }

    .disubheaderText {
        font-size: 0.8em;
    }

    .diregularText {
        font-size: 0.7em;
        margin-top: 10px;
    }

    .dilinkText {
        font-size: 0.7em;
        margin-top: 10px;
    }

    .diheaderText {
        font-size: 1.5em;
    }

    /* Adjust text size for smaller screens */
    .center {
        display: flex;
        flex-flow: column;
        flex: 1;
        margin-top: 0px;
    }

    .sideList {
        display: none;
    }

    .messageContainer {
        width: 80%;
        padding-left: 10px;
        padding-right: 10px;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .attendants {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        height: auto;
        text-align: left;
        /* You can adjust this value based on your layout */
    }

}