/* Drawer.css */
.drawer {
    width: 0;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #212121;
    overflow-x: hidden;
    transition: 0.3s;
    z-index: 250;
    font-family: "Montserrat";
}

.drawer.open {
    width: 300px;
}

.close-button {
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: #555;
    color: white;
}

.drawer-content {
    position: relative;
    top: 50px;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 300px;
}

.paymentButton {
    background-color: rgb(250, 179, 11);
    border-radius: 30px;
    border-color: white;
    width: 150px;
    margin-top: 50px;
    padding: 10px;
    color: white;

    /* Rounded corners */
    padding: 10px 10px;
    margin-left: 50px;
    border-radius: 20px;
    cursor: pointer;
    font-size: medium;
    height: 50px;
    box-shadow: #fff;
}

.paymentButton:disabled {
    background-color: rgb(107, 94, 64);
    border-radius: 30px;
    border-color: white;
    width: 150px;
    margin-top: 50px;
    margin-left: 50px;
    padding: 10px;
    color: white;

    /* Rounded corners */
    padding: 10px 10px;
    border-radius: 20px;
    cursor: not-allowed;
    font-size: medium;
    height: 50px;
    box-shadow: #fff;
}

.athmovil-button {
    width: 89%;
    background-color: rgb(250, 179, 11);
    font-weight: 700;
    margin-top: 4px;
    font-size: small;
    padding: 8px;
    border-radius: 3px;
}

.athmovil-button:disabled {
    width: 89%;
    background-color: rgb(250, 179, 11);
    opacity: 50%;
    border-radius: 3px;
}


.acceptance {
    margin-left: 10px;
    font-size: small;
}

.agreementCheckbox {
    height: 5px;
    width: 5px;
    color: rgb(27, 129, 201);
    border-radius: 25px;
    border-color: #555;
}

.agreementList {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    font-size: small;
    align-items: flex-start;
}

.benefitsList {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-left: 10px;
    font-size: small;
    align-items: flex-start;
}