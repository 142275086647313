/* carousel.css */
.carousel {
    position: absolute;
    width: 100%;
    top: 10px;
    left: 0px;
    /* Set the desired height */
    display: flex;
    overflow: hidden;
    height: 100vh;
    padding: 0%;
    /* Set the carousel height to 100vh */
}

.carousel-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

.carousel-image.active {
    opacity: 1;
}

/* Adjust text size for smaller screens */
@media (max-width: 768px) {
    .carousel {
        display: none;
    }
}

/* Adjust text size for smaller screens */
@media (max-width: 820px) {
    .carousel {
        display: none;
    }
}