.footerStyle {

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    bottom: 0px;
    width: 100%;
}

.footerLink {
    color: white;
}

.footerLink:hover {
    color: rgb(254, 179, 11);
    scale: 1.1;
}