.loginHeader {
    font-size: 22px;
    font-weight: bold;
    color: white;
    height: auto;
    font-family: 'Montserrat', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.loginStyle {
    color: white;
    font-size: 1.0em;
    font-family: 'Montserrat', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: flex;
    justify-content: center;
    /* flex: 1; */
}

.loginSubheader {
    font-size: 14px;
    font-weight: normal;
    color: white;
    height: auto;
}

.loginContent {
    justify-content: center;
    flex-direction: column;
    padding-top: 1.5rem
        /* 24px */
    ;
    padding-bottom: 1.5rem
        /* 24px */
    ;
    padding-left: 1rem
        /* 16px */
    ;
    padding-right: 1rem
        /* 16px */
    ;
}

.loginHighlightText {
    color: rgb(250, 179, 11);
    letter-spacing: -0.025em;
    font-size: 1.0em;
    /* transform duration-500 ease-in-out */
}

.loginHighlightText:hover {
    color: rgb(250, 230, 11);
    /* font-size: 1.2em; */
    text-decoration-line: underline;
    /* transform duration-500 ease-in-out */
}

@media (min-width: 768px) {

    .loginContent {
        padding-left: 0px;
        padding-right: 0px;
    }

}