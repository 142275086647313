.logobody {
    z-index: 120;
    position: fixed;
    display: flex;
    flex-flow: row;
    width: 250px;
    height: 50px;
    top: 30px;
    left: 30px;
}

.logoimage {
    z-index: 200;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -5px;
    left: 0px;
}

.logoprompt {
    position: absolute;
    width: 180px;
    font-size: small;
    font-family: 'Montserrat';
    color: white;
    left: 60px;
    text-align: start;
}

/* Adjust text size for smaller screens */
@media (max-width: 867px) {

    .logoprompt {
        font-size: x-small;
    }

    .logobody {
        top: 20px;
        left: 10px;
        width: 60px;
    }

}

/* Adjust text size for smaller screens */
@media (max-width: 768px) {

    .logobody {
        top: 20px;
        left: 20px;
        width: 60px;
    }

}

@media (max-width: 450px) {

    .logoprompt {
        display: none;
    }

}