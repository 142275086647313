
  .loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loading-text {
    margin-left: 10px;
    color: #fff; /* White text color */
  }

.expired-subscriptions-container {
    width: 90%;
    margin: auto;
    position: relative;
  }
  
  
  .subscription-details-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px; /* Add a little margin on top */
  }
  
  /* Media query for mobile devices (adjust the max-width as needed) */
  @media (max-width: 768px) {
    .subscription-details-container {
      margin-top: 20px; /* Increase the margin for mobile devices */
    }
  }  
  
  .subscription-details {
    width: 90%;
    height: 100%;
    max-width: 90%;
    padding: 40px;
    background-color: #282c34;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: white; /* Change text color to white */
  }
  
  .subscription-details h1 {
    font-size: 2em; /* Adjust the font size as needed */
    color: rgb(250, 179, 11);/* Change the title color to yellow */
  }
  
  /* Add this style for white color on h2, p, and span elements */
  .subscription-details h3,
  .subscription-details p,
  .subscription-details span {
    color: white;
  }
  
  .text-white {
    color: #fff;
  }
  
  .text-green-700 {
    color: #4caf50;
  }
  
  .text-red-700 {
    color: #f44336;
  }
  
  .font-semibold {
    font-weight: bold;
  }
  
  .description {
    margin-top: 10px;
  }
  
  .expires,
  .end-date {
    margin-top: 5px;
  }
  
  .benefits-list {
    margin-top: 10px;
  }
  
  .benefit-item {
    margin-top: 5px;
  }
  
  .renew-text {
    margin-top: 10px;
  }

  .end-date {
    color: red; /* Set color to red for the "Ending" text */
  }