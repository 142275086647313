.registerStyle {
    color: white;
    overflow-y: scroll;
    justify-content: center;
    font-family: 'Montserrat', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.colorAccent {
    color: rgb(250, 250, 11);
}

.colorAccent:hover {
    color: rgb(250, 250, 11);
}

.buttonAccent:hover {
    background-color: rgb(245, 188, 19);
    color: white;
    border-radius: 1;
    border-color: white;
}

.buttonAccent {
    background-color: rgb(245, 188, 19);
    border-radius: 1;
    border-color: white;
}

.acceptTermsButton {
    background-color: seagreen;
    padding: 10px;
    border-radius: 5px;
    color: white;

}