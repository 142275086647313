.main-bkg {
  height: 100%;
  width: 100%;
}

.body.show-red-banner {
  position: relative;
}

.banner-link {
  text-decoration: none;
}

.red-banner {
  background-color: red;
  color: white;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.orange-banner {
  background-color: darkorange;
  color: white;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 110;
  font-size: medium;
}


/* Adjust text size for smaller screens */
@media (max-width: 820px) {
  .main-bkg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-image: url('../photos/meettheboss.jpg');
    /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    z-index: -10;
  }

  .optionsButton {
    display: flex;
    height: 100px;
    width: auto;
    position: relative;
    z-index: 50;
  }

  .orange-banner {
    background-color: darkorange;
    color: white;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 110;
    font-size: small;
  }

}


.optionsButton {
  display: none;
  position: relative;
  height: 70px;
  width: 500px;
  z-index: 80;
}

.social {
  display: none;
  position: relative;
  height: 40px;
  width: 100px;
  color: rgba(259, 179, 11);
  z-index: 1001;
  margin: 10px;
  font-size: medium;
}