/* CSS for navBarStyles */
.navbar-full {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    height: 80px;
    z-index: 120;
    border-bottom: 2px solid rgb(250, 179, 11);
    background-color: black;
}


.navbar-dd {
    display: none;
}

.navbar-container {
    margin-top: 40px;
}


/* CSS for navbarOptionsStyles */
.navbaroptions {
    color: white;
    margin: 10px;
    text-decoration: none;
    /* Apply the Montserrat font */
    font-size: 1rem;
    /* Adjust the font size as needed */
}

/* CSS for navDropDownStyles */




/* CSS for navBarStyles */
/* .navbar {
    position: fixed;
    top: 10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    height: 50px;
    z-index: 100;
  }
  
  /* CSS for navbarOptionsStyles */
.navbar-option {
    color: white;
    margin: 10px;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    transition: color 0.7s, background-color 0.7s;
    opacity: 1;
}

/* CSS for activeLinkStyles */
.active-link {
    color: rgb(250, 179, 11);
    font-family: 'Montserrat', sans-serif;
    background-color: transparent;
    border-bottom: 2px solid rgb(250, 179, 11);
    transition: color 0.7s, background-color 0.7s, border-bottom 0.7s;
}

@media (max-width: 900px) {
    .navbar-full {
        display: none;

    }

    .nav-dropdown {
        position: fixed;
        top: 60px;
        /* Adjust this to position the dropdown below the navbar */
        left: 0;
        width: 100%;
        top: 70px;
        display: flex;
        flex-direction: column;
        /* Display options vertically in the dropdown */
        background: black;
        /* Background color for the dropdown */
        z-index: 200;
        padding: 10px 0;
        /* Add padding on top and bottom of the dropdown */


    }

    .navbar-title {
        top: 22px;
        position: relative;
    }

    .navbar-dd {
        position: fixed;
        color: rgb(250, 179, 11);
        top: 0px;
        /* Adjust this to position the dropdown below the navbar */
        left: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* Display options vertically in the dropdown */
        /* Background color for the dropdown */
        z-index: 120;
        padding: 0px 0px;
        /* Add padding on top and bottom of the dropdown */
        border-bottom: 2px solid rgb(250, 179, 11);
        align-items: center;
        background-color: black;
        height: 75px;
    }

    .navbar-option {
        color: white;
        margin: 10px;
        background-color: rgb(231, 166, 14);
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        font-size: 1rem;
        transition: color 0.7s, background-color 0.7s;
        display: flex;
        justify-content: center;
        border-radius: 20px;
        padding: 5px;
        z-index: 100;
    }


}