.headerStyle {
    position: fixed;
    right: 5px;
    top: 45px;
    z-index: 200;
    font-size: small;
}

@media (max-width: 820px) {
    .headerStyle {
        position: fixed;
        top: 35px;
        right: 0px;
        z-index: 200;
    }
}

.highlightText {
    color: white;
    letter-spacing: -0.025em;
    font-size: 1.0em;
    font-weight: lighter;
    /* transform duration-500 ease-in-out */
}

.highlightText:hover {
    color: rgb(250, 230, 11);
    /* font-size: 1.2em; */
    text-decoration-line: underline;
    /* transform duration-500 ease-in-out */
}