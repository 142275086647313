body {
    background-color: black;
    margin: 0;
    overflow: scroll;
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    height: 100vh;
    width: 100%;
    top: 70px;
    position: relative;
}

.fontDefaults {
    font-family: 'Montserrat', 'Roboto', sans-serif;
    color: white;
}

.footer {
    position: fixed;
    bottom: 0px;
    width: auto;
    height: auto;
    color: white;
    min-width: 200px;
    z-index: 120;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat', 'Roboto', sans-serif;
}

.base {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.accentText {
    color: rgb(250, 179, 11);
}

.accentText:hover {
    color: rgb(250, 230, 11);
}

.primaryButton {
    background-color: rgb(250, 179, 11);
    color: white;
    border: none;
    width: 200px;
    height: 50px;
    /* Adjust the height to make the button rectangular */
    font-size: 1.0rem;
    font-weight: bold;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    z-index: 1000;
    font-family: 'Montserrat', 'Roboto', sans-serif;
    margin: 20px;
    /* Ensure the button is on top of other content */

    /* Floating effect */
    transform: translateY(0);
}

.primaryButton:hover {
    transform: translateY(-5px);
    /* Adjust the value for the desired floating distance */
}


.accentButton {
    background-color: rgb(250, 179, 11);
    border: 1px solid;
    border-radius: 10px;
    padding: 5px 5px;
    border-color: white;
    color: white;
    min-width: 70px;
}

.accentButton:disabled {
    background-color: rgb(143, 108, 26);
    border: 1px solid;
    border-radius: 10px;
    padding: 5px 5px;
    border-color: white;
    color: white;
    min-width: 70px;
}

.accentButton:hover {
    background-color: rgb(250, 179, 11);
    color: white;
    border-radius: 1;
    border-color: white;
}

.cancelButton {
    background-color: rgb(250, 45, 11);
    border-radius: 1;
    border-color: white;
}

.cancelButton:hover {
    background-color: rgb(250, 39, 11);
    color: white;
    border-radius: 1;
    border-color: white;
}


@media (max-width: 820px) {
    .primaryButton {
        border: none;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        width: auto;
        min-width: 100px;
        height: auto;
        /* Adjust the height to make the button rectangular */
        font-size: 0.9rem;
        font-weight: 600;
        transition: transform 0.2s ease-in-out;
        z-index: 1000;
        font-family: 'Montserrat', 'Roboto', sans-serif;
        margin: 15px;
        /* Ensure the button is on top of other content */

        /* Floating effect */
        transform: translateY(0);
    }
}