.section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    font-weight: bold;
}

.content {
    text-align: center;
}