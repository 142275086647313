.main-container {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    max-height: 400px;
    /* Ensure the container takes up the full viewport height */
    margin-top: 550px;
}

.quiz-container {
    max-width: 600px;
    margin: 100 auto;
    padding: 20px;
    max-height: 400px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
}

h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #212121;
}

p {
    font-size: 18px;
    margin: 20px 0;
    color: #212121;
}

.option {
    font-size: 16px;
    padding: 10px 20px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: #a2ab27;
    transition: background-color 0.3s ease;
}

.option:hover {
    background-color: #f0f0f0;
}

.selected {
    background-color: #007bff;
    color: #000000;
}

.next-button {
    font-size: 18px;
    padding: 10px 20px;
    margin: 20px 0;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.next-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.showresults-button {
    font-size: 18px;
    padding: 10px 20px;
    margin: 20px 0;
    background-color: #09990b;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.showresults-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}


.results-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    color: #212121;
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
}