/* Add this CSS class for the fade-in animation */
.tab-fade-in {
    opacity: 0;
    transition: opacity 1.0s ease-in-out;
}

.tab-fade-in.active {
    opacity: 1;
}

.renew-text {
    color: yellow;
    font-size: medium;
    padding: 5px;

}

.cancelled {
    color: red;
}

.renew-btn {
    background-color: #db630d;
    padding: 10px;
}