/* Style for the whole profile page */
.profile-body {
    background-color: lightgray;
    margin: 10px;
    overflow: scroll;
    display: flex;
    width: 100%;
    top: 125px;
    position: relative;
    align-items: flex-start;
  }
  
  /* Style for the left navigation container */
  .profile-container {
    display: flex;
  }
  
  .profile-left-navigation {
    width: 500px;
    overflow-y: auto;
    border-radius: 10px;
    color: #000; /* White font color */
    align-items: center;
  }
  
  /* Style for the navigation links */
  .profile-left-navigation a {
    background-color: rgb(250, 179, 11); /* Yellow when hovered */
    color: #fff; /* White font color */
  }
  
  .profile-left-navigation a:hover,
  .profile-left-navigation a:focus {
    background-color: rgb(250, 179, 11); /* Yellow when hovered */
    color: #fff; /* White font color */
  }
  
  /* Style for the main content */
  .profile-main-content {
    flex: 1;
    /* Add other styles for the main content here */
  }

  .sign-out-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 20px;
    background-color: rgb(250, 179, 11);
    color: white;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
  }
  
  .sign-out-button:hover {
    background-color: white;
    color: yellow;
  }