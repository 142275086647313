.warningHeader {
    background-color: red;
    font-size: 1.0rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-bottom: 1.0rem;
    margin-top: 0.8rem;
    z-index: 110;
    padding: 10px 10px 10px 0px;
}