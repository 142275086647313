/* modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 130;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    z-index: 1001;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
}