/* SearchBox.css */
.search-box {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 20px;
    padding: 8px;
    transition: all 0.5s;
    width: 250px;
}

.search-box.expanded {
    width: 400px;
}

.search-input {
    flex: 1;
    border: 1px solid #212121;
    outline: none;
    padding: 8px;
    border-radius: 20px;
}

.search-icon {
    cursor: pointer;
    margin-left: 10px;
    transition: all 0.3s;
}

.search-icon i {
    font-size: 18px;
    color: #333;
}

.search-icon:hover {
    transform: scale(1.1);
    color: #007bff;
}