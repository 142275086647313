/* monthly.css */
.center {
    display: flex;
}

.messageContainer {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1000;
    min-width: 300px;
}

.headerText {
    color: white;
    font-size: 4.0em;
    font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.subheaderText {
    color: white;
    font-size: 1.2em;
    font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.regularText {
    color: 0.8em;
    margin-top: 20px;
    font-size: medium;
    font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.backgroundImage {
    /* background-image: url('../../../../assets/images/photos//walcrab.jpg'); */
    /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    /* Text color on the background image */
}

.gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0),
            /* Transparent at the top */
            rgba(0, 0, 0, 0.9)
            /* Dark background at the bottom */
        ),
        linear-gradient(to top,
            rgba(0, 0, 0, 0),
            /* Transparent at the bottom */
            rgba(0, 0, 0, 0.9)
            /* Dark background at the top */
        );
}

.vertical-button-list {
    display: flex;
    flex-flow: column;
    /* Display buttons vertically */
    align-items: center;
    /* Center-align buttons horizontally */
    z-index: 50;
    height: 100px;
}

.red-button {
    background: linear-gradient(to bottom right, #ff8000, #ff8000);
    color: white;
    border: 2px solid gainsboro;
    margin: 5px;
    border-radius: 10px;
    padding: 10px 10px;
    cursor: pointer;
    text-overflow: ellipsis;
    font-size: medium;
    transition: background 0.3s;
    width: 300px;
    box-shadow: #fff;
    /* Add a smooth transition for the background */
}

.specials-button {
    background: red;
    color: white;
    border: 2px solid gainsboro;
    border-style: dashed;
    margin: 5px;
    border-radius: 10px;
    padding: 10px 10px;
    cursor: pointer;
    text-overflow: ellipsis;
    font-size: medium;
    width: 300px;
    box-shadow: #fff;
    /* Add a smooth transition for the background */
}

.specials-button:hover {
    border: 4px dashed white;
    /* Show the border on hover */
    border-radius: 20px;
}

.red-button:hover {
    border: 4px solid white;
    /* Show the border on hover */
    border-radius: 20px;
}

.red-button:active,
.red-button:focus {
    border: 3px solid white;
    /* Keep the border when button is clicked or focused */
}


.listsContainer {
    z-index: 50;
    display: flex;
    flex-flow: column;
}

.planButton {
    display: flex;
    flex-flow: column;
    flex: 1;
    font-family: 'Montserrat';
}

@media (max-width: 768px) {

    .center {
        display: flex;
        flex-direction: column;
        /* Stack elements vertically on smaller screens */
        align-items: center;
        /* Center-align content horizontally */
    }

    .messageContainer {
        width: 80%;
        /* Reduce the width for smaller screens */
        padding: 10px;
        /* Reduce padding for smaller screens */
        z-index: 1000;
        text-align: center;
    }

    .headerText {
        color: white;
        font-size: 2.5em;
        /* Adjust the font size for smaller screens */
        font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .subheaderText {
        color: white;
        font-size: 1em;
        /* Adjust the font size for smaller screens */
        font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .regularText {
        color: white;
        /* Correct the color property */
        font-size: 0.9em;
        /* Adjust the font size for smaller screens */
        margin-top: 20px;
        font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .backgroundImage {
        /* background-image: url('../../../../assets/images/photos//walcrab.jpg'); */
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100vh;
        /* Set the height to 100vh to cover the whole viewport height */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        /* Text color on the background image */
    }

    .gradient-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.9)),
            linear-gradient(to top,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.9));
    }

    .vertical-button-list {
        display: flex;
        flex-flow: column;
        align-items: center;
        /* Center-align buttons horizontally */
        z-index: 50;
        height: 100px;
    }

    .red-button {
        background: linear-gradient(to bottom right, #ff8000, #ff8000);
        color: white;
        border: 0.5px solid gainsboro;
        margin: 5px;
        border-radius: 20px;
        padding: 20px 20px;
        cursor: pointer;
        text-overflow: ellipsis;
        vertical-align: middle;
        font-size: 1.0em;
        /* Adjust the button font size for smaller screens */
        width: auto;
        min-width: 300px;
        height: auto;
        min-height: 60px;
        box-shadow: #fff;
        transition: background 0.3s;
    }

    .specials-button {
        background: red;
        color: white;
        border: 2px solid gainsboro;
        border-style: dashed;
        margin: 5px;
        border-radius: 10px;
        padding: 10px 10px;
        cursor: pointer;
        text-overflow: ellipsis;
        font-size: medium;
        width: 300px;
        box-shadow: #fff;
        /* Add a smooth transition for the background */
    }

    .red-button:hover {
        border: 4px solid white;
    }

    .red-button:active,
    .red-button:focus {
        border: 3px solid white;
    }

    .listsContainer {
        z-index: 50;
        display: flex;
        flex-flow: column;
    }

    .planButton {
        display: flex;
        flex-flow: column;
        flex: 1;
    }

    /* Adjust text size for smaller screens */
    @media (max-width: 768px) {
        .headerText {
            font-size: 2.0em;
        }

        .subheaderText {
            font-size: 0.8em;
        }

        .regularText {
            font-size: 0.7em;
        }

        .red-button {
            font-size: 1.0em;
            padding: 10px 10px;
        }
    }
}