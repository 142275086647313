.landingContent {
    background-color: transparent;
    position: absolute;
    top: 100px;
    right: 0%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff;
    z-index: 90;
    height: 75px;
    width: 200px;
    font-family: "Montserrat", "sans-serif";
}

.landingHeaderText {
    color: white;
    font-size: 1.5rem;
}

.landingsubHeaderText {
    color: white;
    font-size: 1rem;
}

.landingRegularText {
    color: white;
    font-size: 80%;
}

.landingImage {
    display: none;
    position: absolute;
    height: 130px;
    width: 130px;
    bottom: -42px;
    left: -120px;
    z-index: 200;
}

/* Adjust text size for smaller screens */
@media (max-width: 768px) {
    .landingImage {
        display: none;
    }

    .landingContent {
        position: absolute;
        top: 160px;
        right: 0px;
        align-items: center;
    }
}